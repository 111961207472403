import { createGlobalStyle} from "styled-components";

export const GlobalStyle = createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    outline: 0; 
    border: 0;
    box-sizing: border-box;
    font-size: 18px;
    font-family: Verdana;
  }
  *:focus {
    outline: 0;
  }
  body {
    overflow-x: hidden;
  }
  html, body, #root {
    height: 100%;
  }
  body, input, button {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  a {
    text-decoration: none;
  }
  ul {
    list-style: none;
  }
  button {
    cursor: pointer;
  }
`
